import kinto from './kinto'
import mobilleo from './mobilleo'
import gohi from './gohi'
import derbygo from './derbygo'
import heart from './heart'
import gosurrey from './gosurrey'
import swift from './swift'
import movezero from './movezero'
import tfwm from './tfwm'

export default { kinto, mobilleo, gohi, derbygo, heart, gosurrey, swift, movezero, tfwm }
